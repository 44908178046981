.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/* my classes */

.project__details--desc{
  padding: 0px;
}
.project__details--desc ol{
  list-style-type: decimal !important;
}
.project__details--desc ol li {
  margin-bottom: 15px;
  background-image: url('https://i.pinimg.com/736x/fe/63/3f/fe633f65fbdb702027be5beeaaf1ce92.jpg');
  /* background-image: url('https://cdn.pixabay.com/photo/2016/05/30/17/26/hook-1425312_640.png'); */
  background-repeat: no-repeat; /* Avoid repeating the image */
  background-position: left center; /* Position the image */
  padding-left: 30px; /* Add space to the left to make room for the image */
  background-size: 20px 20px; /* Control the size of the image */
}
.project__details--desc ul{
  list-style-type: decimal !important;
}
.project__details--desc ul li {
  margin-bottom: 15px;
  background-image: url('https://i.pinimg.com/736x/fe/63/3f/fe633f65fbdb702027be5beeaaf1ce92.jpg');
  background-repeat: no-repeat; /* Avoid repeating the image */
  background-position: left center; /* Position the image */
  padding-left: 30px; /* Add space to the left to make room for the image */
  background-size: 20px 20px; /* Control the size of the image */
}

.slick-dots {
  top: 38%;
  bottom: auto !important;
  width: auto;
  /* left: -18px;
  background: var(--color-background); */
  padding: 14px 10px 3px 0px !important;
  border-radius: 2rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.08);
  position: absolute;
  text-align: center;
  transition: .3s opacity;
  transform: translate3d(0, 0, 0);
  z-index: 55 !important;
  display: flex !important;
  flex-direction: column !important;
  /* Ensures vertical layout */
  align-items: center !important;
  /* Center the dots inside the container */
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 16px !important;
  height: 20px !important;
  margin: 5px 5px !important;
  padding: 0;
  cursor: pointer;
}


.slick-dots li button {
  width: 0.8rem !important;
  height: 0.8rem !important;
  background: #d9d9d9 !important;
  opacity: 1 !important;
  vertical-align: middle !important;
  transition: #00000000 !important;
  margin: 0 0.9rem !important;
  position: relative !important;
  border-radius: 50% !important;

}

.slick-dots li button:before {
  font-size: 0px !important;
  top: -10px !important;
  left: -12px !important;
}

.slick-dots li.slick-active button {
  background: #ed7d1d !important;
}

.slick-dots li.slick-active button:before {
  position: absolute;
  content: "";
  top: -5px !important;
  left: -5px !important;
  width: 2rem;
  height: 2rem;
  border: 2px solid #ed7d1d;
  /* background:#ed7d1d; */
  border-radius: 50%;
  transition: 0.3s;
}
.modalst{
  color: #ffffff;
}
.modalst p{
  color: #ffffff;
}


.pressbtn{
  padding: 5px;
  border-radius: 7px;
  margin: 5px;
  border: 1px solid #ea7038;
  color: #e9692d;
}
.pressbtn:hover{
  color: #ffffff;
  background: #e9692d;
}
.pressbtn.active{
  color: #ffffff;
  background: #e9692d;
}
.preloader {
  position: fixed;
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.preloader__image {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-image: url(../public/assets/img/logo/logo_tejase-transformed.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 60px auto;
  width: 100%;
  height: 100%;
}

.side-icons {
  position: fixed;
  left: 0px;
  top: 245px; 
  z-index: 996;
  padding: 12px 7px;
  border-radius: 0px 10px 10px 0px;
  transition: all 0.4s;
  background-color: #ea81282e;
  color: #fff !important;
}

/*  desktop */
@media (min-width: 768px) {
  .side-icons {
      top: 210px; 
  }
}

.side-icons a {
  font-size: 18px;
}

.side-icons>.insta {
  padding: 5px;
  background-image: linear-gradient(to right top, #f9c14a, #faa83f, #f98e3a, #f6733c, #ef5641, #ea4352, #e03362, #d12871, #bb3588, #9e4499, #7d4fa2, #5856a3);
  border-radius: 20px;
  color: #fff;
}

.side-icons>.insta {
  padding: 5px;
  background-image: linear-gradient(to right top, #f9c14a, #faa83f, #f98e3a, #f6733c, #ef5641, #ea4352, #e03362, #d12871, #bb3588, #9e4499, #7d4fa2, #5856a3);
  border-radius: 20px;
  color: #fff;
}

.side-icons>.facebook {
  padding: 5px;
  background: #3A589B;
  border-radius: 20px;
  color: #fff;
}
.side-icons>.twitter {
  padding: 5px;
  background: #000;
  border-radius: 20px;
  color: #fff;
}
.side-icons>.youtube {
  padding: 5px;
  background: #FF0000;
  border-radius: 20px;
  color: #fff;
}
.side-icons>.linkedin {
  padding: 5px;
  background: #0a66c2;
  border-radius: 20px;
  color: #fff;
}
.side-icons>.pinterest {
  padding: 5px;
  background: #E60023;
  border-radius: 20px;
  color: #fff;
}
#onetrust-banner-sdk.otFloatingRoundedCorner {
  position: fixed;
  z-index: 99;
  color: #ffffff;
  background-color: #ffffff26;
  width: 26%;
  max-width: 375px;
  border-radius: 2.5px;
  bottom: 1em;
  left: 1em;
  font-size: 16px;
  max-height: 90%;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 0 18px rgba(0, 0, 0, .2);
}

